export default class ProductionPartLoadInventory {
    public id!: number;
    public LocationId!: number;
    public ItemId!: number;
    public TrackedItemId!: number;
    public FloorLocationId!: number;
    public PutAwayDateTime?: Date;
    public InboundTransactionId!: number;
    public OutboundTransactionId?: number;

    constructor(init?: ProductionPartLoadInventory) {
        if (init) {
            this.id = init.id;
            this.LocationId = init.LocationId;
            this.ItemId = init.ItemId;
            this.TrackedItemId = init.TrackedItemId;
            this.FloorLocationId = init.FloorLocationId;
            this.PutAwayDateTime = init.PutAwayDateTime;
            this.InboundTransactionId = init.InboundTransactionId;
            this.OutboundTransactionId = init.OutboundTransactionId;
        }
    }
}
